import { createBrowserHistory } from "history"

type HistoryType = ReturnType<typeof createBrowserHistory>

class RoutingStore {
	private _history
		: HistoryType
		= createBrowserHistory()

	get history() {
		return this._history
	}

	get routes() {
		return {
			root: "/",
			services: {
				index: "/services",
				item: (
					id: string
				): string => {
					return "/services#" + id 
				}
			},
			projects: {
				index: "/projects",
				item: (
					id: string = ":id",
				): string => {
					return `/projects/${id}`
				}
			},
			about: {
				index: "/about"
			},
			careers: {
				index: "/careers"
			},
			contacts: {
				index: "/contacts"
			},
		}
	}

	useHistory = (
		history: HistoryType
	) => {
		this._history = history
	}

	static = (
		path: string,
	): string => {
		return `/static/${path}`
	}
}

export default
new RoutingStore()