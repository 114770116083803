import React from "react"

import "styles/views/projects/views/item/components/result"
import { AltrisProject, AltrisProjectResult } from "typings/General"

export interface ProjectItemPageResultSectionProps {
	results: AltrisProjectResult[]
	metrics: AltrisProject["metrics"]
}

export interface ProjectItemPageResultSectionState {

}

export default
class ProjectItemPageResultSection
extends React.Component<ProjectItemPageResultSectionProps, ProjectItemPageResultSectionState> {
	render() {
		const { results, metrics } = this.props
		return <>
			<section className="pi-result">
				<div className="u-bound inner-wrapper">
					<h2>
						Result
					</h2>
					<div className="content">
						<div className="results-wrapper">
							{results.map((result, i) => {
								return <div
									key={i}
									className="result-item"
								>
									<h3>
										{result.caption}
									</h3>
									<ul className="u-list">
										{result.description.map((item, j) => {
											return <li key={j}>
												{item}
											</li>
										})}
									</ul>
								</div>
							})}
						</div>
						{metrics &&
							<div className="metrics-wrapper">
								<p>
									{metrics.overview}
								</p>
								<div className="metrics-list">
									{metrics.items.map((item, i) => {
										return <div
											key={i}
											className="metric-item"
										>
											<div className="label">
												{item.label}
											</div>
											<div className="value">
												{item.value}
											</div>
											{item.measure &&
												<div className="measure">
													{item.measure}
												</div>
											}
										</div>
									})}
								</div>	
							</div>
						}
					</div>
				</div>
			</section>
		</>
	}
}