import { action, computed, makeObservable, observable } from "mobx"
import { AltrisService } from "typings/General"

class MainStore {
	@observable
	private _contactFormShown
		: boolean
		= false

	constructor() {
		makeObservable(this)
	}

	readonly contacts
		= {
			email: "info@altris.io",
			phone: "+44 207 046 6263",
			country: "España",
			address: [
				"ALTRIS EUROPE, S.L.",
				"Avda. Juan López Peñalver, 21 (BIC EURONOVA)",
				"29590, Málaga (Málaga), España",
			],
			socials: {
				// facebook: "https://google.com",
				linkedin: "https://www.linkedin.com/company/altris-io/",
				// telegram: "https://google.com",
			},
		}

	readonly services
		: AltrisService[]
		= [
			{
				key: "frontend-development",
				label: "Front-end development",
				description: [
					"Step into a world where user interfaces are not just functional but captivating.  Frontend Services specialize in crafting immersive digital experiences that resonate with your audience. Powered by a team of experts proficient in HTML5, CSS3, and leading frameworks such as React.js, we breathe life into your digital vision.",
					"From sleek websites to intuitive web and mobile app interfaces, we meticulously design every pixel to perfection. Our commitment to responsiveness ensures seamless experiences across all devices, while our attention to detail guarantees a user journey that delights at every touchpoint.",
				],
				solutions: [
					"JavaScript",
					"Angular",
					"React",
					"Vue",
				],
				steps: [
					{
						title: "Understanding Requirements",
						description: "We begin by comprehensively understanding your design preferences and user experience goals.",
					},
					{
						title: "Wireframing & Prototyping",
						description: "Our team creates wireframes and prototypes to visualize the layout and interaction flow, ensuring alignment with your vision.",
					},
					{
						title: "Technology Selection",
						description: "Based on project requirements, we choose appropriate front-end technologies such as JavaScript, Angular, React, or Vue.",
					},
					{
						title: "Development",
						description: "Our developers bring the design to life, implementing responsive and intuitive interfaces that align with the finalized prototypes.",
					},
					{
						title: "Testing",
						description: "Rigorous testing is conducted to ensure compatibility across browsers and devices, addressing any potential usability issues.",
					},
					{
						title: "Optimization",
						description: "We focus on performance optimization, fine-tuning the front-end to achieve a seamless user experience.",
					},
					{
						title: "Client Collaboration",
						description: "Throughout the process, we maintain open communication, seeking your feedback to refine and enhance the final product.",
					},
				],
			},
			{
				key: "backend-development",
				label: "Back-end development",
				description: [
					"Expertise: With a wealth of experience in backend technologies, our team is proficient in crafting scalable, efficient, and secure solutions tailored to your needs. Whether it's API development, database optimization, or server-side scripting, we bring expertise to every aspect of your backend requirements.",
					"Scalability: Anticipating your growth trajectory, we design our backend solutions to scale seamlessly with your business. Be it handling spikes in traffic or accommodating expansion, our systems ensure uninterrupted performance, no matter the demand.",
					"Security: In an era of digital threats, we prioritize the fortification of your backend infrastructure. From robust authentication mechanisms to regular security audits, we ensure your data remains safeguarded against potential breaches.",
					"Integration: Seamlessly integrating with your existing ecosystem is paramount. Our solutions effortlessly mesh with third-party services, frontend interfaces, and across multiple platforms, ensuring a cohesive digital experience.",
				],
				solutions: [
					"Java",
					".Net",
					"Python",
					"PHP",
					"C#",
					"Golang",
				],
				steps: [
					{
						title: "Requirement Analysis",
						description: "Understanding the functional requirements, we identify the necessary back-end functionalities and data handling needs.",
					},
					{
						title: "Tech Stack Selection",
						description: "Based on project requirements, we select an appropriate technology stack, considering factors such as scalability and security.",
					},
					{
						title: "Database Design",
						description: "We design the database architecture, ensuring efficient data storage, retrieval, and management.",
					},
					{
						title: "Development",
						description: "Our developers implement the back-end logic, ensuring it aligns with the overall project architecture and meets performance benchmarks.",
					},
					{
						title: "Integration",
						description: "Seamless integration with front-end components and third-party services is achieved, creating a cohesive and functional application.",
					},
					{
						title: "Testing",
						description: "We focus on performance optimization, fine-tuning the front-end to achieve a seamless user experience.",
					},
					{
						title: "Scalability Planning",
						description: "For future growth, we plan for scalability, ensuring the back-end can handle increased loads without compromising performance.",
					},
				],
			},
			{
				key: "mobile-development",
				label: "Mobile development",
				description: [
					"Altris IO specializes in native and cross-platform development tailored to your needs. With user-centric design, optimized performance, and scalability, we bring your app vision to life.",
					"We prioritize intuitive interfaces and seamless navigation to engage users and foster loyalty.",
					"Every Application developed by our team is optimized for speed and efficiency, guaranteeing smooth performance under any load.",
				],
				solutions: [
					"iOS",
					"Android",
					"Swift",
					"Java",
					"Objective-C",
					"Flutter",
					"React Native",
					"Xamarin",
					"Ionic",
				],
				steps: [
					{
						title: "Platform Selection",
						description: "We begin by understanding your target audience and business objectives to determine whether iOS, Android, or cross-platform development suits your needs.",
					},
					{
						title: "Wireframing and Design",
						description: "Our team creates wireframes and design mockups, ensuring the mobile application aligns with your brand identity and provides a user-friendly experience.",
					},
					{
						title: "Technology Stack",
						description: "We select the appropriate technologies, such as Swift, Java, Flutter, React Native, or Xamarin, based on the chosen platform and project requirements.",
					},
					{
						title: "Development",
						description: "Our developers bring the design to life, coding the application features and functionalities in accordance with the wireframes.",
					},
					{
						title: "Testing",
						description: "Rigorous testing is conducted to identify and rectify any issues related to performance, usability, and compatibility on various devices.",
					},
					{
						title: "Deployment",
						description: "The mobile application is deployed to the respective app stores or platforms, ensuring a smooth and seamless release.",
					},
					{
						title: "Post-Launch Support",
						description: "We provide ongoing support, addressing any post-launch issues, implementing updates, and ensuring the application's continued success.",
					},
				],
			},
			{
				key: "quality-assurance",
				label: "Quality assurance",
				description: [
					"In our Quality Assurance services, meticulous testing ensures flawless performance. With expertise across platforms, we guarantee your product meets the highest standards of quality and reliability.",
					"Thorough Testing: Our experienced QA team conducts comprehensive testing across various platforms and devices, leaving no stone unturned to identify and rectify any issues before they impact your users.",
					"Attention to Detail: We believe in perfection. From functionality and usability to performance and security, we scrutinize every aspect of your product to ensure it exceeds expectations.",
					"Continuous Improvement: Quality assurance is not just a one-time process but an ongoing commitment. We continually refine our testing methodologies and tools to adapt to the evolving needs of your project.",
				],
				solutions: [
					"Functional",
					"Module",
					"Automotive",
					"Load",
				],
				steps: [
					{
						title: "Test Planning",
						description: "A comprehensive test plan is developed, outlining the testing approach, scope, and resources required.",
					},
					{
						title: "Test Case Design",
						description: "Detailed test cases are created, covering functional, regression, and performance testing aspects.",
					},
					{
						title: "Execution",
						description: "Tests are executed, and results are meticulously analyzed to identify and address any issues or bugs.",
					},
					{
						title: "Automation",
						description: "Where applicable, we implement test automation to enhance efficiency, especially for repetitive and time-consuming testing scenarios.",
					},
					{
						title: "Load Testing",
						description: "Performance is evaluated under different load conditions to ensure the system's stability and responsiveness.",
					},
					{
						title: "Feedback Loop",
						description: "We maintain a continuous feedback loop with developers, addressing issues promptly and ensuring quality is ingrained in every phase of development.",
					},
					{
						title: "Documentation",
						description: "Thorough documentation of the testing process and outcomes is provided, enhancing transparency and facilitating future improvements.",
					},
				],
			},
			{
				key: "cloud-devops",
				label: "Cloud & DevOps",
				description: [
					"Experience efficiency and scalability with our Cloud & DevOps Services. We specialize in optimizing your infrastructure, ensuring seamless deployment and management of your applications.",
					"We optimize your cloud resources, ensuring cost-effectiveness without compromising performance or reliability. With DevOps practices, we automate your development pipelines, enabling frequent and reliable software releases.",
				],
				solutions: [
					"AWS",
					"Microsoft Azure",
					"Google Cloud",
					"DevOps",
					"MLOps",
				],
				steps: [
					{
						title: "Infrastructure Planning",
						description: "We assess your project's requirements and plan the cloud infrastructure, selecting the appropriate services from AWS, Microsoft Azure, or Google Cloud.",
					},
					{
						title: "CI/CD",
						description: "Implementing Continuous Integration/Continuous Deployment pipelines ensures seamless code integration, automated testing, and efficient deployment.",
					},
					{
						title: "Monitoring & Optimization",
						description: "We establish monitoring systems to track application performance and resource usage, optimizing configurations for efficiency.",
					},
					{
						title: "Security Measures",
						description: "Implementing robust security measures, including access controls and encryption, safeguards your data and infrastructure.",
					},
					{
						title: "Scalability Planning",
						description: "Our team ensures your application can scale effortlessly to accommodate increased user loads and demand.",
					},
					{
						title: "Automation",
						description: "Where possible, we automate routine operational tasks, enhancing efficiency and reducing the risk of human error.",
					},
					{
						title: "Collaboration",
						description: "Continuous collaboration between development and operations teams ensures a smooth and efficient deployment and maintenance process.",
					},
				],
			},
			{
				key: "data-analytics",
				label: "Data & Analytics services",
				description: [
					"Unlock the power of your data with our Data Analytics Services. We specialize in extracting actionable insights from your data, empowering you to make informed decisions and drive business growth. Our team of data experts combines domain knowledge with cutting-edge analytics techniques to deliver innovative solutions tailored to your business needs.",
					"Our solutions are designed to scale with your business and adapt to evolving data needs, whether you're dealing with large volumes of data or exploring new sources and types of data.",
				],
				solutions: [
					"Oracle",
					"Teradata",
					"Greenplum",
					"Postgres",
				],
				steps: [
					{
						title: "Data Discovery",
						description: "We conduct an in-depth analysis to understand your data sources, formats, and business objectives for comprehensive data discovery.",
					},
					{
						title: "Data Integration",
						description: "Integrating diverse data sources, we ensure a unified view, facilitating meaningful analysis and insights.",
					},
					{
						title: "Data Processing",
						description: "Applying data cleaning and transformation techniques, we prepare data for analysis, ensuring accuracy and consistency.",
					},
					{
						title: "Analysis and Visualization",
						description: "Leveraging tools and techniques, we perform data analysis and present insights through clear visualizations for informed decision-making.",
					},
					{
						title: "Predictive Analytics",
						description: "Where applicable, we implement predictive modeling to forecast trends and outcomes based on historical data.",
					},
					{
						title: "Data Security",
						description: "Implementing robust security measures, we ensure the confidentiality and integrity of your data throughout the analytics process.",
					},
					{
						title: "Continuous Improvement",
						description: "Our approach involves iterative analysis and refinement, ensuring your data strategy evolves to meet changing business needs.",
					},
				],
			},
			{
				key: "crm-bpm",
				label: "CRM & BPM services",
				description: [
					"Optimizing customer relationship and business process management, our services streamline operations for enhanced efficiency and productivity, utilizing leading platforms for effective solutions",
					"Beyond implementing CRM and BPM solutions, we specialize in tailoring these systems to align with your unique business processes. Our approach involves not only technology integration but also strategic consulting to maximize the value derived from these critical business tools.",
				],
				solutions: [
					"Camunda",
					"Activiti",
					"Siebel",
					"Creatio",
					"SuiteCRM",
					"SugarCRM",
					"vTiger",
					"Digital consulting services",
					"Solutions discovery",
					"Product discovery",
					"Technology & Architecture advisory",
					"Agile, process & tools consulting",
				],
				steps: [
					{
						title: "Process Analysis",
						description: "We conduct a thorough analysis of your existing processes, identifying opportunities for streamlining and improvement.",
					},
					{
						title: "Platform Selection",
						description: "Based on your requirements, we select the most suitable CRM or BPM platform, considering factors like scalability and customization options.",
					},
					{
						title: "Customization",
						description: "Tailoring the chosen platform to align with your specific business processes and requirements for optimal functionality.",
					},
					{
						title: "Integration",
						description: "Ensuring seamless integration with existing systems, including data migration and synchronization, to maintain operational continuity.",
					},
					{
						title: "Training",
						description: "Providing comprehensive training sessions to ensure your team can effectively use and leverage the CRM or BPM system.",
					},
					{
						title: "Support and Maintenance",
						description: "Offering ongoing support and maintenance to address any issues, implement updates, and optimize system performance.",
					},
					{
						title: "Strategic Consulting",
						description: "Beyond technical implementation, we provide strategic consulting to maximize the value derived from CRM or BPM tools, aligning them with your long-term business goals.",
					},
				],
			},
		]

	get defaultService() {
		return this.services[0]
	}

	@computed
	get isContactsFormShown() {
		return this._contactFormShown
	}

	@action
	showContactsForm = () => {
		this._contactFormShown = true
	}

	@action
	hideContactsForm = () => {
		this._contactFormShown = false
	}
}

export default new MainStore()