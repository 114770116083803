import React from "react"

import "styles/components/delimiter"

export interface DelimiterProps {
	light?: boolean
	children?: React.ReactNode
}

export interface DelimiterState {

}

export default
class Delimiter
extends React.Component<DelimiterProps, DelimiterState> {
	render() {
		return <>
			<div className={`c-delimiter ${this.props.light ? "light" : ""}`}>
				{this.props.children &&
					<p>
						{this.props.children}
					</p>
				}
			</div>
		</>
	}
}