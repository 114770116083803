import React from "react"

import "styles/views/homepage/sections/primary"

import GenericButton from "components/Buttons/Generic"
import Main from "stores/Main"
import TiDes from "components/Meta/TiDes"

export interface HomepagePrimarySectionProps {

}

export interface HomepagePrimarySectionState {

}

export default
class HomepagePrimarySection
extends React.Component<HomepagePrimarySectionProps, HomepagePrimarySectionState> {
	private title
		: string
		= "Transform your concepts into Product with our expert development team"

	private description
		: string
		= "Explore a New Era of innovation and Operational efficiency. Open the door to unmatched success and sustainable growth for your business."
		
	render() {
		return <>
			<TiDes
				title={this.title}
				description={this.description}
			/>
			
			<section className="hp-primary u-bound">
				<div className="text-content">
					<h1>
						{this.title}
					</h1>
					<p>
						{this.description}
					</p>
				</div>
				<div className="actions">
					<GenericButton
						onClick={Main.showContactsForm}
					>
						Collaborate Now
					</GenericButton>
				</div>
			</section>
		</>
	}
}