import React from "react"

import "styles/views/services/sections/primary"

import { AltrisService } from "typings/General"

import Main from "stores/Main"

import FancyButton from "components/Buttons/Fancy"
import SolutionsList from "components/SolutionsList"
import TiDes from "components/Meta/TiDes"

export interface ServicesPagePrimarySectionProps {
	selected: AltrisService
	onSelect: (
		serviceId: string,
	) => void
}

export interface ServicesPagePrimarySectionState {

}

export default
class ServicesPagePrimarySection
extends React.Component<ServicesPagePrimarySectionProps, ServicesPagePrimarySectionState> {
	private title
		: string
		= "We deliver a diverse array of state-of-the-art solutions."

	private description
		: string
		= "Our solutions can help our clients to automate their operations, optimize their workflows, and transform their businesses."

	render() {
		const { selected } = this.props
		return <>
			<TiDes
				title={this.title}
				description={this.description}
			/>
			<section className="sp-primary">
				<div className="inner-wrapper u-bound">
					<header>
						<h1 className="h2-like">
							{this.title}
						</h1>
						<p>
							{this.description}
						</p>
					</header>
					<div className="services-list">
						{Main.services.map(service => {
							return <FancyButton
								key={service.key}
								mode="dark"
								active={service.key == selected.key}
								onClick={() => this.props.onSelect(service.key)}
							>
								{service.label}
							</FancyButton>
						})}
					</div>
					<div
						id="selectedService"
						className="selected-service-content"
					>
						<h2>
							{selected.label}
						</h2>
						<div className="content">
							<p>
								{selected.description.map((par, i) => {
									return <React.Fragment key={i}>
										{par}
										{i < selected.description.length - 1 && <>
											<br />
											<br />
										</>}
									</React.Fragment>
								})}
							</p>
							<SolutionsList items={selected.solutions} />
						</div>
					</div>
				</div>
			</section>
		</>
	}
}