import React from "react"

import "styles/views/about/sections/values"

import Routing from "stores/Routing"

import Delimiter from "components/Delimiter"
import AccordionContent from "components/AccordionContent"

export interface AboutPageValuesSectionProps {

}

export interface AboutPageValuesSectionState {
	active: number
}

export default
class AboutPageValuesSection
extends React.Component<AboutPageValuesSectionProps, AboutPageValuesSectionState> {
	private data
		: {
			title: string
			description: string
		}[]
		= [
			{
				title: "Excellence", 
				description: "At Altris IO, we uphold a commitment to excellence in every aspect of our work. We strive for quality, precision, and continuous improvement to exceed expectations and deliver outstanding results.",
			},
			{
				title: "Integrity", 
				description: "Integrity is the cornerstone of our values. We conduct business with honesty, transparency, and ethical standards, fostering trust among our team members, clients, and partners.",
			},
			{
				title: "Innovation", 
				description: "Embracing innovation is vital to our identity. We actively seek new and creative solutions, staying at the forefront of technology trends to drive progress and provide our clients with cutting-edge services.",
			},
			{
				title: "Collaboration", 
				description: "We believe in the power of collaboration. By fostering a culture of teamwork, communication, and mutual support, we leverage the diverse strengths of our team to deliver cohesive and effective solutions for our clients.",
			},
		]

	private timeoutAmount
		: number
		= 5000

	private timeoutId?
		: number

	private autoscroller
		: null | HTMLDivElement

	state
		: AboutPageValuesSectionState
		= {
			active: 0,
		}
	
	componentDidMount(): void {
		document.addEventListener("scroll", this.handleScroll)
	}

	componentWillUnmount(): void {
		clearTimeout(this.timeoutId)
		document.removeEventListener("scroll", this.handleScroll)
	}

	handleScroll = () => {
		if (!this.autoscroller)
			return

		const { top, bottom } = this.autoscroller.getBoundingClientRect()
		const offsetTop = top
		const offsetBottom = document.body.offsetHeight - bottom

		const isVisible = offsetTop > -100 && offsetBottom > -100
		if (isVisible)
			this.startTimer()
		else
			this.stopTimer()
	}

	stopTimer = () => {
		if (typeof this.timeoutId != "undefined")
			clearTimeout(this.timeoutId)

		this.timeoutId = undefined
	}

	startTimer = (
		force: boolean = false,
	) => {
		if (typeof this.timeoutId == "undefined" || force)
			this.timeoutId = window.setTimeout(this.nextSection, this.timeoutAmount)
	}

	setActiveSection = (
		active: number,
	) => {
		this.setState({
			active,
		})
		this.startTimer(true)
	}

	nextSection = () => {
		let { active } = this.state
		active++
		if (active >= this.data.length)
			active = 0

		this.setActiveSection(active)
	}

	forceSection = (
		newActive: number,
	) => {
		if (this.state.active == newActive)
			return

		this.stopTimer()
		this.setActiveSection(newActive)
	}

	render() {
		return <>
			<section className="ap-values">
				<div className="inner-wrapper u-bound">
					<Delimiter light>
						Our values
					</Delimiter>
					<header>
						<h2>
							Our foundation is built on unwavering commitment, innovation & collaboration.
						</h2>
						<p>
							Our values drive us to exceed expectations, ensuring enduring partnerships and shared success with our clients.
						</p>
					</header>
					<div
						ref={r => this.autoscroller = r}
						className="autoscroller"
					>
						<div className="sections">
							{this.data.map((section, i) => {
								const isActive = i == this.state.active
								return <div
									key={i}
									className={`item ${isActive ? "active" : ""}`}
								>
									<h3
										onClick={() => this.forceSection(i)}
									>
										{section.title}
									</h3>
									<i className="fas fa-chevron-down" />
									<AccordionContent
										expanded={isActive}
									>
										{section.description}
										<div className="image">
											<img
												src={Routing.static(`images/values/${i + 1}.jpg`)}
												alt={section.title}
											/>
										</div>
									</AccordionContent>
								</div>
							})}
						</div>
						<div
							className="image"
							style={{
								backgroundImage: `url("${Routing.static(`images/values/${this.state.active + 1}.jpg`)}")`
							}}
						/>
					</div>
				</div>
			</section>
		</>
	}
}