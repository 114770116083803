import React from "react"

import "styles/components/forms/inputs/simple"

export interface SimpleInputProps {
	label?: React.ReactNode
	placeholder?: string
	type?: "text" | "email" | "textarea"
	readonly?: boolean
	required?: boolean
	value: string
	onChange: (
		value: string,
	) => void
}

export interface SimpleInputState {

}

export default
class SimpleInput
extends React.Component<SimpleInputProps, SimpleInputState> {
	handleChange = (
		event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>
	) => {
		if (this.props.readonly)
			return

		this.props.onChange(event.currentTarget.value)
	}

	render() {
		const {
			type = "text",
			readonly = false,
			required = false,
		} = this.props
		return <>
			<div className="c-simple-input">
				{this.props.label &&
					<label>
						{this.props.label}
					</label>
				}
				{type == "textarea"
					? <textarea
						placeholder={this.props.placeholder}
						required={required}
						readOnly={readonly}
						onChange={this.handleChange}
						value={this.props.value}
					/>
					: <input
						type={type}
						placeholder={this.props.placeholder}
						required={required}
						readOnly={readonly}
						onChange={this.handleChange}
						value={this.props.value}
					/>
				}
			</div>
		</>
	}
}