import React from "react"
import { Link, NavLink } from "react-router-dom"

import "styles/components/header"

import Routing from "stores/Routing"

import GenericButton from "./Buttons/Generic"
import Main from "stores/Main"

export interface HeaderProps {

}

export interface HeaderState {
	expanded: boolean
}

export default
class Header
extends React.Component<HeaderProps, HeaderState> {
	state
		: HeaderState
		= {
			expanded: false,
		}

	private links
		: {
			label: string,
			action: string,
			scoped?: boolean,
			disabled?: boolean,
		}[]
		= [
			{
				label: "Our services",
				action: Routing.routes.services.index,
			},
			{
				label: "About us",
				action: Routing.routes.about.index,
			},
			{
				label: "Projects",
				action: Routing.routes.projects.index,
				scoped: true,
			},
			{
				label: "Contacts",
				action: Routing.routes.contacts.index,
			},
			{
				label: "Careers",
				action: Routing.routes.careers.index,
				disabled: true,
			},
		]

	get logo() {
		return <img
			className="logo"
			src={Routing.static("images/logo.svg")}
			alt="Altris IO"
		/>
	}

	toggle = () => {
		this.setState({
			expanded: !this.state.expanded,
		})
	}

	hide = () => {
		this.setState({
			expanded: false,
		})
	}

	render() {
		return <>
			<header className={`c-header u-bound ${this.state.expanded ? "expanded" : ""}`}>
				{Routing.history.location.pathname == Routing.routes.root
					? this.logo
					: <Link
						to={Routing.routes.root}
					>
						{this.logo}
					</Link>
				}
				<div className="links">
					{this.links.map(link => {
						if (link.disabled)
							return <span
								key={link.action}
								className="disabled"
							>
								{link.label}
							</span>
						return <NavLink
							key={link.action}
							to={link.action}
							exact={!link.scoped}
							onClick={this.hide}
						>
							{link.label}
						</NavLink>
					})}
				</div>
				<div className="cta">
					<GenericButton
						onClick={() => {
							this.hide()
							Main.showContactsForm()
						}}
					>
						Contact us
					</GenericButton>
				</div>
				<div
					className="burger"
					onClick={this.toggle}
				>
					<span className="lines" />
				</div>
			</header>
		</>
	}
}