import React from "react"

import "styles/components/sections/about"

import Delimiter from "components/Delimiter"

export interface CrosspageAboutSectionProps {
	renderDelimiter?: boolean
}

export interface CrosspageAboutSectionState {

}

export default
class CrosspageAboutSection
extends React.Component<CrosspageAboutSectionProps, CrosspageAboutSectionState> {
	private counters
		: [string, string][]
		= [
			["100+", "Clients"],
			["50+", "Developers in our team"],
			["15", "Years in development"],
			["50+", "Successfully completed projects"]
		]

	render() {
		return <>
			<section className="c-about u-bound">
				{this.props.renderDelimiter &&
					<Delimiter>
						About Altris IO
					</Delimiter>
				}
				<div className="text-content">
					<h2>
						Crafting your digital future with bespoke development solutions
					</h2>
					<p>
						Together, let's shape a tech-enabled future that surpasses expectations. Welcome to where your digital aspirations take flight.
					</p>
				</div>
				<div className="counters">
					{this.counters.map((counter, i) => {
						return <div
							key={i}
							className="counter-item"
						>
							<div className="number">
								{counter[0]}
							</div>
							<p className="label">
								{counter[1]}
							</p>
						</div>
					})}
				</div>
			</section>
		</>
	}
}