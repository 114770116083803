import React from "react"
import Request from "superagent"

import "styles/components/forms/contact-form"

import SimpleInput from "./Inputs/Simple"
import GenericButton from "components/Buttons/Generic"

export interface ContactFormProps {
	onSuccess: () => void
	onFailure: () => void
}

export interface ContactFormState {
	data: {
		name: string
		email: string
		message: string
	}
	processing: boolean
}

export default
class ContactForm
extends React.Component<ContactFormProps, ContactFormState> {
	state
		: ContactFormState
		= {
			data: {
				name: "",
				email: "",
				message: "",
			},
			processing: false,
		}

	submitStart = () => {
		this.setState({
			processing: true,
		})

		const formData = new FormData()
		formData.set("name", this.state.data.name)
		formData.set("email", this.state.data.email)
		formData.set("message", this.state.data.message)

		Request
			.post("/contactus.php")
			.send(formData)
			.then(this.submitFinish)
			.catch(this.submitCancel)
	}

	submitFinish = () => {
		this.setState({
			processing: false,
			data: {
				name: "",
				email: "",
				message: "",
			}
		})
		this.props.onSuccess()
	}

	submitCancel = () => {
		this.setState({
			processing: false,
		})
		this.props.onFailure()
	}

	handleChange = (
		key: keyof ContactFormState["data"],
	) => {
		return (
			value: string,
		) => {
			const { data } = this.state
			data[key] = value.trimStart()
			this.setState({
				data,
			})
		}
	}

	handleSubmit = (
		event: React.FormEvent<HTMLFormElement>
	) => {
		event.preventDefault()

		if (!this.state.processing) {
			console.log("Submitted data: ", this.state.data)
			this.submitStart()
		}

		return false
	}

	render() {
		const { data } = this.state
		return <>
			<form
				className="c-contact-form"
				onSubmit={this.handleSubmit}
			>
				<h2>
					Get in touch with us
				</h2>
				<div className="row double">
					<SimpleInput
						label="Your name"
						placeholder="Your name"
						required
						readonly={this.state.processing}
						value={data.name}
						onChange={this.handleChange("name")}
					/>
					<SimpleInput
						label="Your email"
						placeholder="Your email"
						type="email"
						required
						readonly={this.state.processing}
						value={data.email}
						onChange={this.handleChange("email")}
					/>
				</div>
				<div className="row">
					<SimpleInput
						label="Your message"
						placeholder="Your message"
						type="textarea"
						readonly={this.state.processing}
						value={data.message}
						onChange={this.handleChange("message")}
					/>
				</div>
				<div className="button-wrapper">
					<button className={this.state.processing ? "disabled" : undefined}>
						<GenericButton
							style="light"
							showPreloader={this.state.processing}
						>
							Get in touch
						</GenericButton>
					</button>
				</div>
			</form>
		</>
	}
}