import React from "react"
import { RouteComponentProps } from "react-router-dom"

import "styles/views/homepage"
import HomepagePrimarySection from "./sections/Primary"
import HomepageVideoSection from "./sections/Video"
import HomepageOutsourcingSection from "./sections/Outsourcing"
import HomepageSolutionsSection from "./sections/Solutions"
import CrosspageProjectsSection from "../../components/Sections/Projects"
import CrosspageAboutSection from "../../components/Sections/About"
import CrosspageContactsSection from "../../components/Sections/Contacts"
import CrosspageFAQSection from "../../components/Sections/FAQ"
import CrosspageHugeTextSection from "../../components/Sections/HugeText"

export interface HomepageProps
extends RouteComponentProps<any> {

}

export interface HomepageState {

}

export default
class Homepage
extends React.Component<HomepageProps, HomepageState> {
	render() {
		return <>
			<main className="v-homepage">
				<HomepagePrimarySection />
				<HomepageVideoSection />
				<HomepageOutsourcingSection />
				<HomepageSolutionsSection />
				<CrosspageProjectsSection />
				<CrosspageAboutSection renderDelimiter />
				<CrosspageContactsSection />
				<CrosspageFAQSection />
				<CrosspageHugeTextSection />
			</main>
		</>
	}
}