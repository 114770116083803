import React from "react"
import { observer } from "mobx-react"
import { Helmet } from "react-helmet"
import { Switch, Route } from "react-router-dom"

// TODO import global typings

import App from "App"

export interface ProviderProps {

}

export interface ProviderState {

}

@observer
export default
class Provider
extends React.Component<ProviderProps, ProviderState> {
	render() {
		return <>
			<Helmet
				titleTemplate="%s • Altris IO"
			>
				<link
					rel="stylesheet" 
					href="/static/shared/font-awesome/style.css"
				/>
				<link rel="apple-touch-icon" sizes="180x180" href="/static/images/favicons/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/static/images/favicons/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/static/images/favicons/favicon-16x16.png" />
				<link rel="manifest" href="/static/images/favicons/site.webmanifest" />
				<link rel="mask-icon" href="/static/images/favicons/safari-pinned-tab.svg" color="#3a4cf5" />
				<meta name="msapplication-TileColor" content="#ffffff" />
				<meta name="theme-color" content="#ffffff" />

				<meta property="og:site_name" content="Altris IO" />
			</Helmet>
			<Switch>
				<Route
					path="/"
					component={App}
				/>
			</Switch>
		</>
	}
}