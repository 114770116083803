import React from "react"
import { RouteComponentProps } from "react-router-dom"

import "styles/views/projects/views/item"

import Routing from "stores/Routing"

import Breadcrumbs from "components/Breadcrumbs"
import CrosspageProjectsSection from "components/Sections/Projects"
import CrosspageContactsSection from "components/Sections/Contacts"

// import "styles/views/projects/views/item"

import { AltrisProject } from "typings/General"
import ProjectItemPageInfoSection from "./components/Info"
import ProjectItemPageSolutionSection from "./components/Solution"
import ProjectItemPageResultSection from "./components/Result"
import TiDes from "components/Meta/TiDes"

export interface ProjectItemPageProps
extends RouteComponentProps<{ id: string }> {
	item: AltrisProject
}

export interface ProjectItemPageState {

}

export default
class ProjectItemPage
extends React.Component<ProjectItemPageProps, ProjectItemPageState> {
	render() {
		const { item } = this.props
		return <>
			<TiDes
				title={item.name}
				description={item.taskInfo.join("\n")}
				image={item.image}
			/>
			<main className="v-project-item">
				<header className="u-bound">
					<Breadcrumbs
						items={[
							{
								label: "Home",
								link: Routing.routes.root,
							},
							{
								label: "Projects",
								link: Routing.routes.projects.index,
							},
							{
								label: item.name,
								link: Routing.routes.projects.item(item.id),
							}
						]}
					/>
					<div className="content-wrapper">
						<h1 className="h2-like">
							{item.name}
						</h1>
						<div className="counters">
							{item.counters.map((counter, i) => {
								return <div
									key={i}
									className="counter"
								>
									<span className="number">
										{counter.value}
									</span>
									<p>
										{counter.label}
									</p>
								</div>
							})}
						</div>
					</div>
				</header>

				<div
					className="pi-image"
					style={{
						backgroundImage: `url("${item.image}")`
					}}
				/>

				<ProjectItemPageInfoSection
					data={item}
				/>
				<ProjectItemPageSolutionSection
					steps={item.solution}
				/>
				<ProjectItemPageResultSection
					metrics={item.metrics}
					results={item.results}
				/>
				<CrosspageProjectsSection
					exclude={item.id}
				/>
				<CrosspageContactsSection />
			</main>
		</>
	}
}