import React from "react"

import "styles/views/about/sections/primary"

import GenericButton from "components/Buttons/Generic"
import Main from "stores/Main"
import TiDes from "components/Meta/TiDes"

export interface AboutPagePrimarySectionProps {

}

export interface AboutPagePrimarySectionState {

}

export default
class AboutPagePrimarySection
extends React.Component<AboutPagePrimarySectionProps, AboutPagePrimarySectionState> {
	private title
		: string
		= "We empower business that focus on innovation & sustainability"

	private description
		: string
		= "Collaborate Now"

	render() {
		return <>
			<TiDes
				title={this.title}
				description={this.description}
			/>
			<section className="ap-primary">
				<div className="inner-wrapper u-bound">
					<h1>
						{this.title}
					</h1>
					<div className="cta-wrapper">
						<GenericButton
							onClick={Main.showContactsForm}
						>
							{this.description}
						</GenericButton>
					</div>
				</div>
			</section>
		</>
	}
}