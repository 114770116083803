import React from "react"

import "styles/views/contacts/sections/form"

import ContactForm from "components/Forms/ContactForm"
import Routing from "stores/Routing"
import Main from "stores/Main"
import Alerts from "stores/Alerts"
import TiDes from "components/Meta/TiDes"

export interface ContactsPageFormSectionProps {

}

export interface ContactsPageFormSectionState {

}

export default
class ContactsPageFormSection
extends React.Component<ContactsPageFormSectionProps, ContactsPageFormSectionState> {
	private title
		: string
		= "Contact us"

	private description
		: string
		= "Let us know how we can help"

	renderContact = (
		icon: string,
		caption: string,
		description?: React.ReactNode,
	) => {
		return <div className="contact-item">
			<img src={Routing.static(`images/icons/contacts/${icon}.svg`)} />
			<div className="content">
				<div className="caption">
					{caption}
				</div>
				{description &&
					<div className="description">
						{description}
					</div>
				}
			</div>
		</div>
	}

	render() {
		return <>
			<TiDes
				title={this.title}
				description={this.description}
			/>
			<section className="cp-form">
				<div className="inner-wrapper u-bound">
					<div className="left">
						<header>
							<h1>
								{this.title}
							</h1>
							<p>
								{this.description}
							</p>
						</header>
						<div className="contacts">
							<div className="column">
								{this.renderContact(
									"location",
									Main.contacts.country,
									Main.contacts.address.map((line, i) => {
										return <p key={i}>
											{line}
										</p>
									})
								)}
							</div>
							<div className="column noshrink">
								{this.renderContact("mail", Main.contacts.email)}
								{this.renderContact("phone", Main.contacts.phone)}
							</div>
						</div>
					</div>
					<div className="right">
						<ContactForm
							onSuccess={Alerts.showSuccessContactAlert}
							onFailure={Alerts.showFailedContactAlert}
						/>
					</div>
				</div>
			</section>
		</>
	}
}