import { AltrisProject } from "typings/General"

const project1: AltrisProject = {
	id: "data-warehouse-architecture-design",
	name: "Data warehouse architecture design",
	category: "data-analytics",
	image: "/static/images/projects/data-warehouse-architecture-design.png",
	counters: [
		{
			label: "Terabytes of storage",
			value: "60 000",
		},
		{
			label: "Events per second",
			value: "14 000 000",
		},
	],
	companyInfo: [
		"A large telecommunication company. More than 88 million subscribers use its services. The operator serves the largest 80.4-million subscriber base, 5 640 customer service showrooms",
	],
	taskInfo: [
		"Design data warehouse architectures and migrate from non-target systems (SAS, Teradata) to target systems (Greenplum, Hadoop) and develop ETL processes.",
	],
	timeframes: "13 months",
	scope: "4 560 human/day",
	techs: [
		"Python",
		"Apache Airflow",
		"Apache Spark",
	],
	team: [
		"1 Project manager",
		"6 Business Analysts",
		"4 Data Engineers",
		"1 Architect",
		"1 Product Owner",
	],
	solution: [
		{
			caption: "Migration of ETL Processes",
			description: [
				"The first challenge involved migrating ETL processes from SAS and Teradata-based storage to new storage on Hadoop and Greenplum. This required the team to:"
			],
			list: [
				"Adapt and rewrite ETL processes to a different programming language.",
				"Develop mechanisms to load data from all sources into the new repositories.",
				"Select optimal data storage schemes and determine naming and table structure to meet project requirements.",
				"Rewrite and adapt data in cases where the table structure in the new repository differed from the source.",
			]
		},
		{
			caption: "Performance Optimization",
			description: [
				"The second challenge focused on improving the performance of the ETL processes. To address this, the team:"
			],
			list: [
				"Optimized changes to the data model and table structure, including revising the index structure and splitting large tables into smaller ones.",
				"Changed the approach to loading data, optimizing query structure, and other methods to enhance ETL process performance.",
				"Utilized Python, Apache Spark, and Apache Airflow for ETL process migration.",
				"Implemented strategies such as incremental data loading and loading data in installments to accelerate the process and enhance performance.",
			]
		},
	],
	results: [
		{
			caption: "Data Migration and Infrastructure Setup",
			description: [
				"Data successfully migrated to new storage.",
				"Installation of new storage infrastructure completed.",
				"Data sources effectively connected to the system.",
			],
		},
		{
			caption: "Improved ETL Process Performance",
			description: [
				"Significant performance improvements achieved.",
				"Faster processing and loading of data observed.",
				"Reduced process execution time experienced.",
				"Increased data efficiency noted.",
			],
		},
		{
			caption: "Performance Enhancements through Optimization",
			description: [
				"Changes to data model and table structure optimized.",
				"Data loading approach modified for efficiency.",
				"Queries rewritten for improved performance.",
			],
		},
		{
			caption: "Efficient Resource Utilization:",
			description: [
				"Optimization of ETL processes and data loading approach led to more efficient use of system resources.",
			],
		},
	],
	metrics: {
		overview: "Optimizing ETL processes and changing the approach to data loading allowed for more efficient use of system resources.",
		items: [
			{
				label: "Terabytes of storage",
				value: "60 000",
			},
			{
				label: "Events",
				value: "14 000 000",
				measure: "per second",
			},
			{
				label: "Terabytes of data",
				value: "100",
				measure: "per Day",
			},
			{
				label: "Terabytes of data",
				value: "700",
				measure: "per Week",
			},
			{
				label: "Terabytes of data",
				value: "33 600",
				measure: "per Year",
			},
		],
	},
}

const project2: AltrisProject = {
	id: "data-warehouse-architecture-design-2",
	name: "Data warehouse architecture design #2",
	category: "data-analytics",
	image: "/static/images/projects/data-warehouse-architecture-design-2.png",
	counters: [
		{
			label: "Terabytes of data",
			value: "200",
		},
		{
			label: "Reduced pending requests in the system",
			value: "~20%",
		},
	],
	companyInfo: [
		"Largest bank, with 107.6 million retail customers and over 3 million corporate customers. Net profit in March increased by 9%. The total number of employees of the group is 287,866 people",
	],
	taskInfo: [
		"To design data warehouse architecture and migrate from Teradata to Hadoop and Greenplum and develop a data warehouse for retail banking.",
	],
	timeframes: "2 years",
	scope: "21 900 human/day",
	techs: [
		"Greenplum",
		"Airflow",
		"Qlikview",
	],
	team: [
		"1 Project manager",
		"4 Business Analysts",
		"5 Data Engineers",
		"1 Architect",
		"1 Product Owner",
	],
	solution: [
		{
			caption: "Data Integration and Quality Control",
			description: [
				"The project began by addressing the core challenge of providing up-to-date customer data and enabling communication campaign assembly and ML model scoring. This step involved:"
			],
			list: [
				"Establishing dependencies between data loads from various sources to ensure timely updates in the warehouse.",
				"Implementing data quality control mechanisms to identify and address potential issues promptly.",
			]
		},
		{
			caption: "Operational Mode Configuration",
			description: [
				"The project proceeded to configure operational modes for the data warehouse to optimize its functionality. This step included:"
			],
			list: [
				"Configuring night mode to prioritize ETL processes and data updates during low-activity periods.",
				"Allocating additional resources and priority for business users to efficiently interact with data during day mode.",
			]
		},
	],
	results: [
		{
			caption: "Successful Data Provision and Calculation Enablement",
			description: [
				"The project team effectively addressed the challenge of delivering up-to-date data and facilitating calculations for communication campaigns and ML model scoring.",
			],
		},
		{
			caption: "Enhanced User Experience and System Health Monitoring",
			description: [
				"Implemented a data catalog and system health monitoring system to enhance user experience.",
				"The system provided real-time information on system performance and allowed for the aborting of problematic queries, improving stability and reliability.",
			],
		},
		{
			caption: "Migration to Hadoop and Greenplum",
			description: [
				"The team executed a successful migration from Teradata to Hadoop and Greenplum.",
				"Migration was conducted in blocks, with each block migrating independently, ensuring smooth transition.",
				"The retail segment's data lake was migrated to Hadoop first, followed by frequently used data showcases recreated on the same platform.",
				"Subsequently, all other data and user objects were migrated to the Greenplum platform, retaining the data structure from Teradata.",
			],
		},
		{
			caption: "Data Transfer and Migration Details",
			description: [
				"Various critical data, including bank deposits, loans, transactions, cards, and customer tables, were uploaded and migrated.",
				"The migration process transferred 500 gigabytes of data daily, totaling 3.5 terabytes per week and 168 terabytes per year.",
				"ETL processes were migrated efficiently using Apache Airflow, ensuring seamless data transition.",
				"Subsequently, all other data and user objects were migrated to the Greenplum platform, retaining the data structure from Teradata.",
			],
		},
		{
			caption: "What systems the solution is integrated with",
			description: [
				"QlikView",
				"Retail ORACLE SIEBEL CRM",
			],
		},
		{
			caption: "Data Transfer and Migration Details",
			description: [
				"Data is loaded from ABS, CRM, RBS and other systems. Initially, the data were loaded into Greenplum via Teradata, but the reporting and showcases have already been moved to Greenplum. Then a direct connection to the sources was set up.",
				"Way4",
				"SmarVista",
				"Automated Banking System (ABS)",
				"Siebel",
			],
		},
	],
	metrics: {
		overview: "Optimizing ETL processes and changing the approach to data loading allowed for more efficient use of system resources.",
		items: [
			{
				label: "Terabytes of data",
				value: "200",
			},
			{
				label: "Reduced pending requests in the system by",
				value: "~20%",
			},
		],
	},
}

const project3: AltrisProject = {
	id: "data-warehouse-architecture-design-3",
	name: "Data warehouse architecture design #3",
	category: "data-analytics",
	image: "/static/images/projects/data-warehouse-architecture-design-3.png",
	counters: [
		{
			label: "Raw data volume",
			value: "40 TB",
		},
		{
			label: "Product showcases",
			value: "4 TB",
		},
	],
	companyInfo: [
		"A manufacturing company, the company's products are used in consumer goods and automotive, construction, energy, and chemical and other industries in 90 countries around the world. The plants have the capacity to produce about 1.8 million tons of products per year.",
		"The number of employees of the group is more than 40,000 people. Number of customers -- 1.8 thousand.",
	],
	taskInfo: [
		"To design a new data warehouse and develop data showcases for operational reporting in the enterprise.",
	],
	timeframes: "12 months",
	scope: "17 000 human/day",
	techs: [
		"Vertica",
		"Airflow",
		"Tableau",
		"Apache Kafka",
	],
	team: [
		"1 Project manager",
		"6 Business Analysts",
		"5 Data Engineers",
		"1 Architect",
		"1 Product Owner",
	],
	solution: [
		{
			caption: "Standardization and Centralization",
			description: [
				"The initial challenge involved disparate process chains across different plants, hindering the creation of a universal solution. To overcome this obstacle, the team developed a solution enabling standardized processes, centralized management, and cost reduction across all plants."
			],
		},
		{
			caption: "Modernization and Efficiency Improvement",
			description: [
				"The subsequent challenge revolved around eliminating outdated scripts and processes within the company. To transition to industrialized data showcases, outdated code and processes needed addressing. The team tackled this by migrating systems to modern and efficient solutions such as PROM (Process Orchestration and Monitoring) and CI/CD (Continuous Integration and Continuous Deployment)."
			],
		},
	],
	results: [
		{
			caption: "Storage Installation and BI Implementation",
			description: [
				"Storage infrastructure was installed, and Business Intelligence (BI) tools were implemented.",
			],
		},
		{
			caption: "Warehouse Implementation on HP Vertica Platform",
			description: [
				"The project team established the warehouse on the HP Vertica platform, integrating Apache Kafka with the Manufacturing Execution System (MES).",
				"Data is streamed into the Data Lake via Apache Kafka and then uploaded to the HP Vertica warehouse, ensuring efficient processing and storage of MES data for analysis.",
			],
		},
		{
			caption: "Successful Pilot Operation of Dashboards",
			description: [
				"In April 2023, pilot operations of dashboards were successfully completed at seven different enterprises.",
				"Results from the pilot operations confirmed the effectiveness and value of dashboards for operational monitoring and analysis of key production indicators.",
				"Dashboards provided crucial information for informed decision-making and process improvement in production.",
			],
		},
		{
			caption: "Data Transfer and Migration Details",
			description: [
				"Various production-related data, including raw material consumption, product output, and other indicators, were uploaded and migrated.",
				"The migration process transferred 10 gigabytes of data daily, totaling 70 gigabytes per week and 3.36 terabytes per year.",
				"ETL processes were efficiently migrated using Apache Airflow, ensuring smooth data transition.",
			],
		},
		{
			caption: "What systems the solution is integrated with",
			description: [
				"Tableu",
				"FineBi",
			],
		},
		{
			caption: "From which systems is data pulled? ",
			description: [
				"MES (Manufacturing Execution System)",
				"The warehouse is new, data is pulled from manufacturing MES systems by uploading files, then it is already laid out via ETL in Vertica and assembled there into showcases.",
			],
		},
	],
	metrics: {
		overview: "Optimizing ETL processes and changing the approach to data loading allowed for more efficient use of system resources.",
		items: [
			{
				label: "Dashboard with",
				value: "10",
				measure: "core metrics",
			},
			{
				label: "Dashboard with",
				value: "200",
				measure: "sub-metrics",
			},
			{
				label: "Raw data volume",
				value: "40 TB",
			},
			{
				label: "Product showcases",
				value: "4 TB",
			},
		],
	},
}

const project4: AltrisProject = {
	id: "testing-trusted-services-platform-project",
	name: "Testing trusted services platform project",
	category: "quality-assurance",
	image: "/static/images/projects/testing-trusted-services-platform-project.jpg",
	counters: [
		{
			label: "Tests run speed increased ",
			value: "400%",
		},
		{
			label: "Team productivity increased",
			value: "235%",
		},
		{
			label: "Errors reduced",
			value: "30%",
		},
	],
	companyInfo: [
		"A manufacturing company that has a large IT subsidiary. It conducts its own software development, provides support and development of corporate IT systems, develops software robots, deals with project management, import substitution, and applies artificial intelligence and machine learning.",
	],
	taskInfo: [
		"To test a trusted services platform that automates processes around the direct provision of services of a certification center and cryptographic protection authority.",
	],
	timeframes: "25 months",
	scope: "3 040 human/day",
	techs: [
		"Selenium",
		"Adaptavista",
		"Jmeter",
		"Postman",
		"Java 8",
		"TestNG",
		"RestAssured",
		"Hibernate",
		"AllureReport",
	],
	team: [
		"1 Tech lead",
		"3 Autotesters",
	],
	solution: [
		{
			caption: "Project Structuring and Monitoring",
			description: [
				"The team addressed the primary challenge posed by the project's size and functional complexity. This involved:"
			],
			list: [
				"Structuring project information using Jira and Adaptavista tools to suit project requirements.",
				"Estimating the scope of work for functional topics using Jira components and categorizing releases by topic.",
				"Implementing a process to collect and analyze project metrics regularly, focusing on defects, task estimation, release results, and work progress.",
				"Conducting detailed bug analysis using DevTools, application logs, and autotest reports to identify resolutions and performance improvements.",
				"Employing automated testing for most project functionality, with daily test runs in GitLab providing real-time status updates and issue localization.",
				"Utilizing Allure reports to assess functionality operability and error detection.",
			]
		},
		{
			caption: "Comprehensive Testing and Quality Assurance",
			description: [
				"The team ensured comprehensive testing and quality assurance through various methods:"
			],
			list: [
				"Performing load testing using JMeter to evaluate system compliance with customer requirements and identify potential boundaries.",
				"Checking testing quality through metrics assessment.",
				"Conducting regression testing before release prolongation to maintain system stability.",
				"Testing servers and information systems for load capacity to guarantee reliable system operation.",
			]
		},
	],
	results: [
		{
			caption: "Automated Test Model Implementation",
			description: [
				"Automation of the test model reduced the time spent on re-testing previously validated functionality, thereby expediting the development and delivery of new features to the client.",
			],
		},
		{
			caption: "Identification and Correction of Issues",
			description: [
				"Testing facilitated the detection and rectification of bugs, defects, and malfunctions within the system, ensuring improved performance and reliability.",
			],
		},
		{
			caption: "Delivery of High-Quality Product",
			description: [
				"As a result of testing, the client received a high-quality product characterized by enhanced consistency and reliability in operation.",
			],
		},
		{
			caption: "What systems the solution is integrated with",
			description: [
				"State Tax Service",
				"IASUP - Unified Information System for Personnel Management based on SAP HCM (IASUP)",
				"System of access to corporate resources of employees (in-house development of the customer - ARIDA).",
			],
		},
	],
	metrics: {
		overview: "Optimizing ETL processes and changing the approach to data loading allowed for more efficient use of system resources.",
		items: [
			{
				label: "Tests run speed increased by",
				value: "400%",
				measure: "core metrics",
			},
			{
				label: "Team productivity increased by",
				value: "235%",
				measure: "sub-metrics",
			},
			{
				label: "Errors reduced by",
				value: "30%",
				measure: "sub-metrics",
			},
		],
	},
}

const project5: AltrisProject = {
	id: "crm-e-commerce-implementation",
	name: "CRM E-Commerce implementation",
	category: "crm-bpm",
	image: "/static/images/projects/crm-e-commerce-implementation.jpg",
	counters: [],
	taskInfo: [
		"Realize the transformation of an outdated CRM system and implement E-Commerce to increase the efficiency of customer interaction and improve business processes.",
	],
	timeframes: "30 months",
	scope: "4 200 human/day",
	techs: [
		"Oracle",
		"Siebel CRM",
		"Oracle BI Publisher",
		"Java",
		"JS",
		"CSS",
	],
	team: [
		"1 Project manager",
		"6 Analysts",
		"6 Testers",
		"2 Architects",
		"3 Frontend developers",
		"1 Scrum master",
		"6 Siebel developers",
	],
	solution: [
		{
			caption: "Modernizing Siebel CRM System",
			description: [
				"The initial challenge involved modernizing the outdated Siebel CRM system, which lacked modern functionality and mobile compatibility. The team overcame this challenge by:"
			],
			list: [
				"Applying an innovative approach and UX principles to enhance user experience.",
				"Involving designers to revamp the system while considering existing limitations.",
				"Significantly improving the functionality and usability of Siebel CRM.",
			]
		},
		{
			caption: "Automation and Integration with SAP",
			description: [
				"The second challenge focused on automating processes and integrating with the SAP system. To address this, the team:"
			],
			list: [
				"Developed personal accounts for clients to access and order documents automatically.",
				"Integrated CRM and SAP systems, eliminating manual data re-entry tasks for sales managers.",
				"Conducting regression testing before release prolongation to maintain system stability.",
				"Implemented a mechanism allowing sales managers to perform calculations directly in CRM, with automatic data transfer to SAP, enhancing efficiency and accuracy.",
			]
		},
	],
	results: [
		{
			caption: "Development of a New CRM System",
			description: [
				"A new CRM system was successfully developed to replace the outdated one.",
				"Parallel work in the old system facilitated a smooth transition to the new CRM.",
			],
		},
		{
			caption: "Enhanced Customer Experience",
			description: [
				"Clients now enjoy a convenient, transparent, and efficient ordering process.",
				"Improved tracking of order fulfillment and access to necessary information/documentation.",
				"Enhanced customer service, satisfaction, and operational efficiency.",
			],
		},
		{
			caption: "Automation of Pricing System",
			description: [
				"Implementation of an automated pricing system eliminated arbitrary pricing by managers.",
				"Prices are now generated objectively based on set parameters and company strategy.",
				"Reduced errors and eliminated arbitrary decisions, ensuring consistent pricing practices.",
			],
		},
	],
}

export const list: AltrisProject[] = [
	project1,
	project2,
	project3,
	project4,
	project5,
]