import React from "react"
import { Helmet } from "react-helmet"

export interface TiDesProps {
	title: string
	description: string
	image?: string
}

export interface TiDesState {

}

export default
class TiDes
extends React.Component<TiDesProps, TiDesState> {
	render() {
		return <Helmet>
			<title>
				{this.props.title}
			</title>
			<meta name="description" content={this.props.description} />

			<meta property="og:title" content={this.props.title} />
			<meta property="og:description" content={this.props.description} />

			{this.props.image &&
				<meta property="og:image" content={window.location.origin + this.props.image} />
			}
		</Helmet>
	}
}