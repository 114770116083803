import React from "react"
import { RouteComponentProps } from "react-router-dom"

import "styles/views/about"

import CrosspageAboutSection from "components/Sections/About"
import CrosspageContactsSection from "components/Sections/Contacts"
import AboutPagePrimarySection from "./sections/Primary"
import AboutPageImageSection from "./sections/Image"
import AboutPageInfoCardsSection from "./sections/InfoCards"
import AboutPageValuesSection from "./sections/Values"

export interface AboutPageProps
extends RouteComponentProps<any> {

}

export interface AboutPageState {

}

export default
class AboutPage
extends React.Component<AboutPageProps, AboutPageState> {
	render() {
		return <>
			<main className="v-about">
				<AboutPagePrimarySection />
				<AboutPageImageSection />
				<CrosspageAboutSection />
				<AboutPageValuesSection />
				<AboutPageInfoCardsSection />
				<CrosspageContactsSection />
			</main>
		</>
	}
}