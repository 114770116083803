import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"

import "styles/components/breadcrumbs"

export type Breadcrumb = {
	label: string
	link: string
}

export interface BreadcrumbsProps {
	items: Breadcrumb[]
}

export interface BreadcrumbsState {

}

export default
class Breadcrumbs
extends React.Component<BreadcrumbsProps, BreadcrumbsState> {
	render() {
		return <>
			<Helmet>
				<script type="application/ld+json">
					{`
						{
							"@context": "https://schema.org",
							"@type": "BreadcrumbList",
							"itemListElement":
							[
								${this.props.items.map((item, i) => {
									return `{
										"@type": "ListItem",
										"position": ${i + 1},
										"item": {
											"@id": "${window.location.hostname}${item.link}",
											"name": "${item.label}"
										}
									}`
								}).join(",\n")}
							]
						}
					`}
				</script>
			</Helmet>
			<ol className="c-breadcrumbs">
				{this.props.items.map((item, i) => {
					return <li key={i}>
						<Link
							to={item.link}
							aria-current={i == this.props.items.length - 1 ? "page" : undefined}
						>
							{item.label}
						</Link>
					</li>
				})}
			</ol>
		</>
	}
}