import React from "react"
import { action, computed, makeObservable, observable } from "mobx"

export interface AlertData {
	title: React.ReactNode
	description: React.ReactNode
}

class AlertsStore {
	@observable
	private _data?
		: AlertData
		= undefined

	constructor() {
		makeObservable(this)
	}

	@computed
	get isVisible() {
		return !!this._data
	}

	@computed
	get data() {
		return this._data!
	}

	@action
	show = (
		title: React.ReactNode,
		description: React.ReactNode,
	) => {
		this._data = {
			title,
			description,
		}
	}

	showSuccessContactAlert = () => {
		this.show(
			"Thank You for Your Inquiry!",
			<>
				Your message has been successfully submitted. We appreciate you reaching out to us. 
				<br /><br />
				Our team will get in touch with you shortly.
			</>
		)
	}

	showFailedContactAlert = () => {
		this.show(
			"Something went wrong :(",
			"Please, try again later"
		)
	}

	@action
	hide = () => {
		this._data = undefined
	}
}

export default new AlertsStore()