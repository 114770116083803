import React from "react"

import "styles/components/accordion-content"

import { EasingFunction, EasingFunctions, FRAME_DURATION_60 } from "utils/animation"

export interface AccordionContentProps {
	expanded: boolean
	className?: string
	easing?: EasingFunction
	duration?: number
	minHeight?: number
	children?: React.ReactNode
}

export interface AccordionContentState {
	height?: number | "auto"
	display: boolean
}

export default
class AccordionContent
extends React.Component<AccordionContentProps, AccordionContentState> {
	static defaultProps
		= {
			easing: "easeInOutQuad" as EasingFunction,
			duration: 600,
			minHeight: 0,
		}

	private wrapper
		: HTMLDivElement | null

	private frame
		: number

	private transitionParams
		= {
			from: 0,
			to: 0,
		}

	private animate = (
		timingRel: number = 0 
	) => {
		if (timingRel >= 1) {
			this.finish()
		} else {
			const { duration, easing } = this.props
			const { from, to } = this.transitionParams
			const diff = to - from

			const newHeight = from + diff * EasingFunctions[easing!](timingRel)

			this.setState({
				height: newHeight
			})

			this.frame = requestAnimationFrame(() => {
				this.animate(timingRel + FRAME_DURATION_60 / duration!)
			})
		}
	}

	private start = () => {
		this.setState({
			display: this.props.expanded || this.state.display,
		}, () => {
			this.transitionParams.from = this.wrapper?.offsetHeight || 0
			this.transitionParams.to = this.props.expanded 
				? this.wrapper?.scrollHeight || 0
				: this.props.minHeight!
	
			this.setState({
				height: this.transitionParams.from,
			})
			this.animate()
		})
	}

	private finish = () => {
		this.setState({
			height: this.props.expanded
				? "auto"
				: this.props.minHeight,
			display: this.props.expanded
		})
	}

	private interrupt = () => {
		cancelAnimationFrame(this.frame)
	}

	state
		: AccordionContentState
		= {
			height: this.props.expanded
				? "auto"
				: this.props.minHeight,
			display: this.props.expanded
		}

	componentDidUpdate(prevProps: AccordionContentProps) {
		const { expanded: oldExpanded } = prevProps
		const { expanded: newExpanded } = this.props
		if (oldExpanded != newExpanded) {
			this.interrupt()
			this.start()
		}
	}

	componentWillUnmount() {
		this.interrupt()
	}

	render() {
		const { height } = this.state
		return <>
			<div
				ref={r => this.wrapper = r}
				className={`c-accordion-content ${this.props.className || ""}`}
				style={{
					overflow: height == "auto"
						? undefined
						: "hidden",
					height,
				}}
			>
				{(this.state.display || !!this.props.minHeight) && this.props.children}
			</div>
		</>
	}
}