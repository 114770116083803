import Swipeable, { SwipeableDirection } from "components/HOC/Swipeable"
import React from "react"
import Routing from "stores/Routing"

import "styles/views/homepage/sections/outsourcing"

export interface HomepageOutsourcingSectionProps {

}

export interface HomepageOutsourcingSectionState {
	shift: number
}

export default
class HomepageOutsourcingSection
extends React.Component<HomepageOutsourcingSectionProps, HomepageOutsourcingSectionState> {
	private cards
		: {
			heading: string
			description: string
		}[]
		= [
			{
				heading: "Optimize costs",
				description: "Our outsourcing solutions optimize operational costs effectively, leveraging lower labor expenses. This allows your company to offer competitive prices while steering clear of the overhead of maintaining an in-house team.",
			},
			{
				heading: "Access to technologies you need",
				description: "We propose the most suitable technology to meet your needs and deliver top-notch specialists to ensure the successful realization of your goals.",
			},
			{
				heading: "Accelerate your project with swift delivery.",
				description: "Avoid the lengthy wait and substantial expenses. Opt for our services, and you'll have your MVP fast enough to validate your market strategy without spending millions.",
			},
		]

	state
		: HomepageOutsourcingSectionState
		= {
			shift: 0
		}

	increaseShift = () => {
		let { shift } = this.state
		shift += 1
		if (shift > this.cards.length - 1)
			shift = this.cards.length - 1
		this.setState({
			shift,
		})
	}

	decreaseShift = () => {
		let { shift } = this.state
		shift -= 1
		if (shift < 0)
			shift = 0
		this.setState({
			shift,
		})
	}

	handleSwipe = (
		direction: SwipeableDirection,
	) => {
		switch (direction) {
			case "left":
				return this.increaseShift()
			case "right":
				return this.decreaseShift()
		}
	}

	render() {
		return <>
			<section className="hp-outsourcing u-bound">
				<h2>
					Strategic outsourcing
				</h2>
				<Swipeable
					onSwipe={this.handleSwipe}
				>
					<div className="cards-outer-wrapper">
						<div
							className="cards"
							style={{
								"--shift": this.state.shift,
							} as React.CSSProperties}
						>
							{this.cards.map((card, i) => {
								return <div
									key={i}
									className="u-card"
								>
									<img src={Routing.static(`images/icons/outsourcing/${i + 1}.svg`)} />
									<div className="text-content">
										<h3>
											{card.heading}
										</h3>
										<p>
											{card.description}
										</p>
									</div>
								</div>
							})}
						</div>
					</div>
				</Swipeable>
				<div className="cards-shifter">
					<img
						src={Routing.static("images/icons/arrow_long_left.svg")}
						onClick={this.decreaseShift}
					/>
					<div className="counter">
						<span className="current">
							{this.state.shift + 1}
						</span> / {this.cards.length}
					</div>
					<img
						src={Routing.static("images/icons/arrow_long_right.svg")}
						onClick={this.increaseShift}
					/>
				</div>
			</section>
		</>
	}
}