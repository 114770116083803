import React from "react"

import "styles/views/projects/views/item/components/info"

import { AltrisProject } from "typings/General"

import Routing from "stores/Routing"

export interface ProjectItemPageInfoSectionProps {
	data: AltrisProject
}

export interface ProjectItemPageInfoSectionState {

}

export default
class ProjectItemPageInfoSection
extends React.Component<ProjectItemPageInfoSectionProps, ProjectItemPageInfoSectionState> {
	render() {
		const { companyInfo, taskInfo, timeframes, scope, techs, team } = this.props.data
		return <>
			<section className="pi-info">
				<div className="u-bound inner-wrapper">
					<div className="cards">
						{companyInfo &&
							<div className="u-card align-top">
								<img src={Routing.static(`images/icons/outline_sphere.svg`)} />
								<div className="text-content">
									<h3>
										Company information
									</h3>
									{companyInfo.map((info, i) => {
										return <p key={i}>
											{info}
										</p>
									})}
								</div>
							</div>
						}
						<div className="u-card align-top">
							<img src={Routing.static(`images/icons/outline_cube.svg`)} />
							<div className="text-content">
								<h3>
									Task
								</h3>
								{taskInfo.map((info, i) => {
									return <p key={i}>
										{info}
									</p>
								})}
							</div>
						</div>
					</div>
					<div className="counters">
						<div className="counter">
							<span>
								Timeframes
							</span>
							<p>
								{timeframes}
							</p>
						</div>
						<div className="counter">
							<span>
								Project scope
							</span>
							<p>
								{scope}
							</p>
						</div>
						<div className="counter">
							<span>
								Technology stack
							</span>
							<ul className="u-list">
								{techs.map((tech, i) => {
									return <li key={i}>
										{tech}
									</li>
								})}
							</ul>
						</div>
						<div className="counter">
							<span>
								Team
							</span>
							<ul className="u-list">
								{team.map((tech, i) => {
									return <li key={i}>
										{tech}
									</li>
								})}
							</ul>
						</div>
					</div>
				</div>
			</section>
		</>
	}
}