import React from "react"

import "styles/components/buttons/fancy"
import Routing from "stores/Routing"

export interface FancyButtonProps {
	onClick?: () => void
	children?: React.ReactNode
	/**
	 * - dark: black outline and black sparkle  
	 * - light: white outline and white sparkle
	 * Default is dark
	 */
	mode?: "light" | "dark"
	active?: boolean
}

export interface FancyButtonState {

}

export default
class FancyButton
extends React.Component<FancyButtonProps, FancyButtonState> {
	render() {
		const {
			mode = "dark",
			active = false,
			onClick,
		} = this.props

		return <>
			<div
				className={`c-fancy-button ${typeof onClick != "undefined" ? "hoverable" : ""} ${mode} ${active ? "active" : ""}`}
				onClick={onClick}
			>
				<span className="content">
					{this.props.children}
				</span>
				<img
					src={Routing.static(`images/icons/sparkle_${mode == "dark" ? "black" : "white"}.svg`)}
				/>
			</div>	
		</>
	}
}