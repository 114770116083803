import React from "react"
import { observer } from "mobx-react"

import "styles/components/modals/alert"

import Alerts from "stores/Alerts"

import GenericModal from "./Generic"

export interface AlertModalProps {

}

export interface AlertModalState {

}

@observer
export default
class AlertModal
extends React.Component<AlertModalProps, AlertModalState> {
	render() {
		if (!Alerts.isVisible)
			return null

		const { title, description } = Alerts.data

		return <>
			<GenericModal
				onClose={Alerts.hide}
			>
				{close => {
					return <div className="c-alert-modal-content">
						<h2>
							{title}
						</h2>
						<p>
							{description}
						</p>
						<div className="actions">
							<span
								className="ok"
								onClick={close}
							>
								Ok
							</span>
						</div>
					</div>
				}}
			</GenericModal>
		</>
	}
}