import React from "react"
import Routing from "stores/Routing"

import "styles/views/homepage/sections/video"

export interface HomepageVideoSectionProps {

}

export interface HomepageVideoSectionState {

}

export default
class HomepageVideoSection
extends React.Component<HomepageVideoSectionProps, HomepageVideoSectionState> {
	render() {
		return <>
			<section className="hp-video u-bound">
				<div className="video-wrapper">
					<video
						src={Routing.static("videos/homepage.mp4")}
						muted
						controls={false}
						autoPlay
						loop
					/>
				</div>
			</section>
		</>
	}
}