import React from "react"
import Routing from "stores/Routing"

import "styles/components/buttons/generic"

export interface GenericButtonProps {
	onClick?: string | (() => void)
	children?: React.ReactNode
	style?: "dark" | "light"
	showPreloader?: boolean
}

export interface GenericButtonState {

}

export default
class GenericButton
extends React.Component<GenericButtonProps, GenericButtonState> {
	imageref
		: {
			[key in NonNullable<GenericButtonProps["style"]>]: string
		}
		= {
			dark: "arrow_tr_white",
			light: "arrow_tr_blue"
		}

	render() {
		const {
			style = "dark",
			onClick
		} = this.props

		const content = <>
			<span className="arrow">
				{this.props.showPreloader
					? <i className="fas fa-spinner fa-pulse" />
					: <img src={Routing.static(`images/icons/${this.imageref[style]}.svg`)} />
				}
			</span>
			<span className="gb-content">
				{this.props.children}
			</span>
		</>

		if (typeof onClick == "string")
			return <a
				className={`c-generic-button ${style}`}
				href={onClick}
				target="_blank"
				rel="noopener"
			>
				{content}
			</a>

		return <>
			<div
				className={`c-generic-button ${style}`}
				onClick={onClick}
			>
				{content}
			</div>
		</>
	}
}