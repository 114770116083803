import React from "react"

export interface SwipeableProps {
	onSwipe?: (
		direction: SwipeableDirection,
	) => void
	threshold?: number
	children?: React.ReactNode
}

export interface SwipeableState {

}

export type SwipeableDirection =
	| "up"
	| "down"
	| "left"
	| "right"

export default
class Swipeable
extends React.Component<SwipeableProps, SwipeableState> {
	startPoint?
		: {
			x: number
			y: number
		}
		= undefined

	timerId
		: number

	get threshold(): number {
		return this.props.threshold ?? 24
	}

	componentDidMount(): void {
		document.addEventListener("touchend", this.handleSwipeEnd)
	}

	componentWillUnmount(): void {
		document.removeEventListener("touchend", this.handleSwipeEnd)
	}

	cancelTouch = () => {
		this.startPoint = undefined
	}

	runTimer = () => {
		this.timerId = window.setTimeout(this.cancelTouch, 300)
	}

	cancelTimer = () => {
		clearTimeout(this.timerId)
	}

	handleSwipeStart = (
		event: React.TouchEvent<HTMLDivElement>,
	) => {
		this.cancelTimer()

		const { screenX: x, screenY: y } = event.touches[0]
		this.startPoint = { x, y }

		this.runTimer()
	}

	handleSwipeEnd = (
		event: TouchEvent,
	) => {
		if (!this.startPoint)
			return

		this.cancelTimer()

		const { screenX: x, screenY: y } = event.changedTouches[0]
		const { x: sx, y: sy } = this.startPoint
		const dx = x - sx
		const dy = y - sy
		const adx = Math.abs(dx)
		const ady = Math.abs(dy)
		const isHorizontal = Math.max(adx, ady) == adx
		if (isHorizontal) {
			if (adx < this.threshold)
				return

			if (dx < 0)
				this.props.onSwipe?.("left")
			else
				this.props.onSwipe?.("right")
		} else {
			if (ady < this.threshold)
				return

			if (dy < 0)
				this.props.onSwipe?.("up")
			else
				this.props.onSwipe?.("down")
		}

		this.cancelTouch()
	}

	render() {
		return <>
			<div
				className="c-swipeable"
				onTouchStart={this.handleSwipeStart}
			>
				{this.props.children}
			</div>
		</>
	}
}