import React from "react"
import { RouteComponentProps } from "react-router-dom"

import "styles/views/contacts"

import ContactsPageFormSection from "./sections/Form"
import CrosspageFAQSection from "components/Sections/FAQ"
import CrosspageHugeTextSection from "components/Sections/HugeText"

export interface ContactsPageProps
extends RouteComponentProps {

}

export interface ContactsPageState {

}

export default
class ContactsPage
extends React.Component<ContactsPageProps, ContactsPageState> {
	render() {
		return <>
			<main className="v-contacts">
				<ContactsPageFormSection />
				<CrosspageFAQSection />
				<CrosspageHugeTextSection />
			</main>
		</>
	}
}