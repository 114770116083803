import React from "react"

import "styles/views/about/sections/info-cards"

import Routing from "stores/Routing"

export interface AboutPageInfoCardsSectionProps {

}

export interface AboutPageInfoCardsSectionState {

}

export default
class AboutPageInfoCardsSection
extends React.Component<AboutPageInfoCardsSectionProps, AboutPageInfoCardsSectionState> {
	private cards
		: {
			icon: string
			title: string
			description: string
		}[]
		= [
			{
				icon: "outline_cube",
				title: "Our vision",
				description: "At Altris IO, we aspire to be a trailblazer in technology outsourcing, driving advancements that redefine industries and empower businesses to thrive in the digital era.",
			},
			{
				icon: "outline_sphere",
				title: "Our mission",
				description: "Provide top-notch development and consulting services, fostering innovation and guiding businesses towards sustained success in an ever-evolving technological landscape.",
			},
		]

	render() {
		return <>
			<section className="ap-info-cards">
				<div className="inner-wrapper u-bound">
					{this.cards.map((card, i) => {
						return <div
							key={i}
							className="card"
						>
							<img src={Routing.static(`images/icons/${card.icon}.svg`)} />
							<h2>
								{card.title}
							</h2>
							<p>
								{card.description}
							</p>
						</div>
					})}
				</div>
			</section>
		</>
	}
}