import React from "react"

import "styles/components/accordion"
import AccordionContent from "./AccordionContent"

export interface AccordionProps {
	title?: React.ReactNode
	/**
	 * Default is false
	 */
	expanded?: boolean
	onToggle?: () => void
	children?: React.ReactNode
}

export interface AccordionState {
	
}

export default
class Accordion
extends React.Component<AccordionProps, AccordionState> {
	render() {
		const { expanded = false } = this.props
		return <>
			<div className={`c-accordion ${expanded ? "expanded" : ""}`}>
				<header
					onClick={this.props.onToggle}
				>
					<h3>
						{this.props.title}
					</h3>
					<i className="fas fa-chevron-down" />
				</header>
				<div className="ac-content-wrapper">
					<AccordionContent
						expanded={expanded}
					>
						<div className="ac-content">
							{this.props.children}
						</div>
					</AccordionContent>
				</div>
			</div>
		</>
	}
}