import Delimiter from "components/Delimiter"
import React from "react"

import "styles/views/projects/views/item/components/solution"
import { AltrisProjectSolution } from "typings/General"

export interface ProjectItemPageSolutionSectionProps {
	steps: AltrisProjectSolution[]
}

export interface ProjectItemPageSolutionSectionState {

}

export default
class ProjectItemPageSolutionSection
extends React.Component<ProjectItemPageSolutionSectionProps, ProjectItemPageSolutionSectionState> {
	render() {
		return <>
			<section className="pi-solution">
				<div className="u-bound inner-wrapper">
					<Delimiter light>
						Solution
					</Delimiter>
					<div className="steps">
						{this.props.steps.map((step, i) => {
							return <div
								key={i}
								className="step"
							>
								<div className="numeration">
									<div className="number">
										{(i + 1).toString().padStart(2, "0")}
									</div>
									<span className="label">
										/ step
									</span>
								</div>
								<h2>
									{step.caption}
								</h2>
								{step.description.map((text, i) => {
									return <p key={i}>
										{text}
									</p>
								})}
								{step.list &&
									<ul className="u-list">
										{step.list.map((item, j) => {
											return <li key={j}>
												{item}
											</li>
										})}
									</ul>
								}
							</div>
						})}
					</div>
				</div>
			</section>
		</>
	}
}