import React from "react"
import Routing from "stores/Routing"

import "styles/components/sections/huge-text"

export interface CrosspageHugeTextSectionProps {

}

export interface CrosspageHugeTextSectionState {

}

export default
class CrosspageHugeTextSection
extends React.Component<CrosspageHugeTextSectionProps, CrosspageHugeTextSectionState> {
	render() {
		return <>
			<section className="c-huge-text">
				<div className="inner-wrapper u-bound">
					<div className="background">
						<img
							src={Routing.static("images/text.svg")}
							alt="Altris IO"
						/>
					</div>
				</div>
			</section>
		</>
	}
}