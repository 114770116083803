import React from "react"

import "styles/components/footer"

import Main from "stores/Main"
import Routing from "stores/Routing"
import { Link } from "react-router-dom"

export interface FooterProps {

}

export interface FooterState {

}

export default
class Footer
extends React.Component<FooterProps, FooterState> {
	private company
		: {
			label: string
			action: string
			disabled?: boolean
		}[]
		= [
			{
				label: "About us",
				action: Routing.routes.about.index,
			},
			{
				label: "Projects",
				action: Routing.routes.projects.index,
			},
			{
				label: "Contact us",
				action: Routing.routes.contacts.index,
			},
			{
				label: "Careers",
				action: Routing.routes.careers.index,
				disabled: true,
			},
		]

	render() {
		const { socials } = Main.contacts
		return <>
			<footer className="c-footer">
				<div className="inner-wrapper u-bound">
					<div className="left">
						<img
							src={Routing.static("images/logo.svg")}
							alt="Altris IO"
						/>
						<div className="company-info">
							{Main.contacts.address.map((line, i) => {
								return <p key={i}>
									{line}
								</p>
							})}
						</div>
						<div className="socials">
							{Object.keys(socials).map(key => {
								return <a
									key={key}
									href={socials[key]}
									target="_blank"
									rel="noopener"
								>
									<img
										src={Routing.static(`images/icons/socials/${key}.svg`)}
										alt={key}
									/>
								</a>
							})}
						</div>
					</div>
					<div className="right">
						<div className="column">
							<header>
								Our Services
							</header>
							<ul>
								{Main.services.map(service => {
									return <li key={service.key}>
										<Link
											to={Routing.routes.services.item(service.key)}
										>
											{service.label}
										</Link>
									</li>
								})}
							</ul>
						</div>
						<div className="column">
							<header>
								Company
							</header>
							<ul>
								{this.company.map((item, i) => {
									if (item.disabled)
										return <li
											key={i}
											className="disabled"
										>
											<span>
												{item.label}
											</span>
										</li>
									return <li key={i}>
										<Link to={item.action}>
											{item.label}
										</Link>
									</li>
								})}
							</ul>
						</div>
					</div>
				</div>
			</footer>
			<div className="c-footerline">
				<div className="inner-wrapper u-bound">
					<div className="copyright">
						&copy;2024 Altris.io
					</div>
					<div className="links">
						<a href="#">
							Terms & Conditions
						</a>
						<a href="#">
							Privacy Policy
						</a>
					</div>
				</div>
			</div>
		</>
	}
}