import React from "react"
import { observer } from "mobx-react"
import { Route, RouteComponentProps, Switch } from "react-router-dom"

import "styles/fonts"
import "styles/animations"
import "styles/main"
import "styles/uni"

import Routing from "stores/Routing"
import Main from "stores/Main"
import Alerts from "stores/Alerts"

import Header from "components/Header"
import Footer from "components/Footer"
import GenericModal from "components/Modals/Generic"
import ContactForm from "components/Forms/ContactForm"

import Homepage from "views/Homepage"
import ServicesPage from "views/Services"
import AboutPage from "views/About"
// import CareersPage from "views/Careers"
import ContactsPage from "views/Contacts"
import AlertModal from "components/Modals/Alert"
import NotFoundPage from "views/NotFound"
import ProjectsScope from "views/Projects"

export interface AppProps
extends RouteComponentProps<any> {

}

export interface AppState {

}

@observer
export default
class App
extends React.Component<AppProps, AppState> {
	componentDidUpdate(
		prevProps: Readonly<AppProps>
	): void {
		if (this.props.location.pathname != prevProps.location.pathname)
			document.documentElement.scrollTo(0, 0)
	}

	render() {
		const { routes } = Routing
		return <>
			<Header />
			<Switch>
				<Route
					path={routes.root}
					exact
					component={Homepage}
				/>
				<Route
					path={routes.services.index}
					exact
					component={ServicesPage}
				/>
				<Route
					path={routes.about.index}
					exact
					component={AboutPage}
				/>
				{/* <Route
					path={routes.careers.index}
					exact
					component={CareersPage}
				/> */}
				<Route
					path={routes.contacts.index}
					exact
					component={ContactsPage}
				/>
				<Route
					path={routes.projects.index}
					component={ProjectsScope}
				/>
				<Route
					component={NotFoundPage}
				/>
			</Switch>
			<Footer />
			{Main.isContactsFormShown &&
				<GenericModal
					onClose={Main.hideContactsForm}
				>
					{close => {
						return <ContactForm
							onSuccess={() => {
								close()
								Alerts.showSuccessContactAlert()
							}}
							onFailure={() => {
								Alerts.showFailedContactAlert()
							}}
						/>
					}}
				</GenericModal>
			}
			<AlertModal />
		</>
	}
}