import React from "react"

import "styles/views/about/sections/image"

export interface AboutPageImageSectionProps {

}

export interface AboutPageImageSectionState {

}

export default
class AboutPageImageSection
extends React.Component<AboutPageImageSectionProps, AboutPageImageSectionState> {
	render() {
		return <>
			<section className="ap-image" />
		</>
	}
}