import React from "react"
import { Route, RouteComponentProps, Switch } from "react-router-dom"
import Routing from "stores/Routing"
import ProjectsListPage from "./views/List"
import NotFoundPage from "views/NotFound"
import { list } from "consts/projects"
import ProjectItemPage from "./views/Item"

export interface ProjectsScopeProps {

}

export interface ProjectsScopeState {

}

export default
class ProjectsScope
extends React.Component<ProjectsScopeProps, ProjectsScopeState> {
	render() {
		const { projects } = Routing.routes
		return <>
			<Switch>
				<Route
					path={projects.index}
					exact
					component={ProjectsListPage}
				/>
				<Route
					path={projects.item()}
					exact
					render={(props: RouteComponentProps<{ id: string }>) => {
						const item = list.find(item => item.id == props.match.params.id)
						if (!item)
							return <NotFoundPage {...props} />
						return <ProjectItemPage
							key={item.id}
							{...props}
							item={item}
						/>
					}}
				/>
				<Route
					component={NotFoundPage}
				/>
			</Switch>
		</>
	}
}