import React from "react"
import { Link, RouteComponentProps } from "react-router-dom"

import "styles/views/projects/views/list"

import { list } from "consts/projects"

import Routing from "stores/Routing"

import CrosspageContactsSection from "components/Sections/Contacts"
import TiDes from "components/Meta/TiDes"

export interface ProjectsListPageProps
extends RouteComponentProps<any> {

}

export interface ProjectsListPageState {

}

export default
class ProjectsListPage
extends React.Component<ProjectsListPageProps, ProjectsListPageState> {
	private title
		: string
		= "Unveil a diverse spectrum of cutting-edge solutions provided by Altris IO"

	private description
		: string
		= "With a dynamic team fueled by creativity and expertise, we transform challenges into opportunities, complexity into simplicity, and ideas into reality. Our commitment to excellence ensures that every project, no matter how distinct, receives the same level of dedication and precision."

	render() {
		return <>
			<TiDes
				title={this.title}
				description={this.description}
			/>
			<main className="v-projects-list">
				<div className="u-bound inner-wrapper">
					<header>
						<h1 className="h2-like">
							{this.title}
						</h1>
						<p>
							{this.description}
						</p>
					</header>
					<div className="projects-list">
						{list.map(item => {
							return <article
								key={item.id}
								className="project-card"
							>
								<div className="image">
									<div className="aspect-ratio-wrapper">
										<img
											src={item.image}
											alt={item.name}
										/>
									</div>
								</div>
								<div className="content">
									<div className="text">
										<h2>
											{item.name}
										</h2>
										<p>
											{item.taskInfo[0]?.slice(0, 100)}...
										</p>
									</div>
									<div className="actions">
										<Link to={Routing.routes.projects.item(item.id)}>
											Read more
										</Link>
									</div>
								</div>
							</article>
						})}
					</div>
				</div>
				<CrosspageContactsSection />
			</main>
		</>
	}
}