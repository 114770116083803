import TiDes from "components/Meta/TiDes"
import React from "react"
import { RouteComponentProps } from "react-router-dom"

import "styles/views/not-found"

export interface NotFoundPageProps
extends RouteComponentProps<any> {
	
}

export interface NotFoundPageState {

}

export default
class NotFoundPage
extends React.Component<NotFoundPageProps, NotFoundPageState> {
	render() {
		return <>
			<TiDes
				title="Not found :("
				description="This page does not exist"
			/>
			<main className="v-not-found">
				<h1>
					Not found :(
				</h1>
			</main>
		</>
	}
}