import CrosspageContactsSection from "components/Sections/Contacts"
import CrosspageProjectsSection from "components/Sections/Projects"
import React from "react"
import { RouteComponentProps } from "react-router-dom"
import Main from "stores/Main"

import "styles/views/services"
import ServicesPagePrimarySection from "./sections/Primary"
import ServicesPageHowItWorksSection from "./sections/HowItWorks"
import Routing from "stores/Routing"

export interface ServicesPageProps
extends RouteComponentProps<any> {

}

export interface ServicesPageState {

}

export default
class ServicesPage
extends React.Component<ServicesPageProps, ServicesPageState> {
	handleServiceSelect = (
		serviceId: string
	) => {
		this.props.history.replace(Routing.routes.services.index + "#" + serviceId)
		document.getElementById("selectedService")
			?.scrollIntoView({
				behavior: "smooth",
				block: "start",
			})
	}

	render() {
		const hash = this.props.location.hash.slice(1)
		const activeService = Main.services.find(item => {
			return item.key == hash
		}) ?? Main.services[0]

		return <>
			<main className="v-services">
				<ServicesPagePrimarySection
					selected={activeService}
					onSelect={this.handleServiceSelect}
				/>
				<ServicesPageHowItWorksSection
					steps={activeService.steps}
				/>
				<CrosspageProjectsSection />
				<CrosspageContactsSection />
			</main>
		</>
	}
}