import React from "react"

import "styles/components/sections/faq"

import Delimiter from "components/Delimiter"
import Accordion from "components/Accordion"

export interface CrosspageFAQSectionProps {

}

export interface CrosspageFAQSectionState {
	expanded?: number
}

export default
class CrosspageFAQSection
extends React.Component<CrosspageFAQSectionProps, CrosspageFAQSectionState> {
	private questions
		: [string, React.ReactNode][]
		= [
			[
				"What services does your outsourcing company offer?",
				<>
					We specialize in a range of outsourcing services, tailored to meet your business needs.
				</>,
			],
			[
				"How can outsourcing benefit my business?",
				<>
					Outsourcing allows you to focus on your core competencies while we handle development tasks. It leads to increased efficiency and cost savings.
				</>,
			],
			[
				"What industries does your outsourcing company serve?",
				<>
					Our services cater to a diverse range of industries, ensuring a customized approach to each client's unique requirements.
				</>,
			],
			[
				"How do you ensure data security and confidentiality?",
				<>
					We prioritize data security and confidentiality through robust measures. We are familiar with GDPR, HIPAA, etc compliances and always make sure that data is secured and encrypted
				</>,
			],
			[
				"What sets your outsourcing company apart from others in the market?",
				<>
					Our commitment to excellence, personalized solutions, and a track record of successful partnerships distinguish us from the rest, providing you with a competitive edge.
				</>,
			],
			[
				"Can I scale the outsourcing services based on my business needs?",
				<>
					Yes, our flexible outsourcing solutions allow you to scale services up or down based on your evolving business requirements, ensuring agility and adaptability.
				</>,
			],
			[
				"How do you ensure the quality of work delivered by your team?",
				<>
					We maintain high standards of quality, regular performance evaluations, and continuous training to keep our team updated with the latest industry standards.
				</>,
			],
			[
				"What is the typical turnaround time for your outsourcing services?",
				<>
					The turnaround time varies based on the nature of the service. However, we are committed to delivering efficient and timely results to meet your business deadlines.
				</>,
			],
			[
				"How do I get started with your outsourcing services?",
				<>
					Getting started is simple! Contact us and we will guide you through the onboarding process tailored to your business needs.
				</>,
			],
			[
				"Can I see examples of successful outsourcing projects you've completed?",
				<>
					Certainly! We can provide case studies and examples of successful projects, once you'll request in Contact Us form
				</>,
			],
		]

	state
		: CrosspageFAQSectionState
		= {
			expanded: undefined
		}

	toggle = (
		index: number,
	) => {
		this.setState({
			expanded: this.state.expanded == index
				? undefined
				: index
		})
	}

	render() {
		return <>
			<section className="c-faq">
				<div className="inner-wrapper u-bound">
					<Delimiter>
						FAQ
					</Delimiter>
					<div className="content">
						<h2>
							Frequent questions?
						</h2>
						<div className="accordions">
							{this.questions.map((question, i) => {
								return <Accordion
									key={i}
									title={question[0]}
									expanded={this.state.expanded == i}
									onToggle={() => this.toggle(i)}
								>
									{question[1]}
								</Accordion>
							})}
						</div>
					</div>
				</div>
			</section>
		</>
	}
}