import React from "react"

import "styles/components/solutions-list"

export interface SolutionsListProps {
	items: string[]
}

export interface SolutionsListState {

}

export default
class SolutionsList
extends React.Component<SolutionsListProps, SolutionsListState> {
	render() {
		const { items } = this.props

		return <>
			<ul
				className="c-solutions-list"
				style={{
					"--count": Math.ceil(items.length / 2)
				} as React.CSSProperties}
			>
				{items.map((solution, i) => {
					return <li key={i}>
						{solution}
					</li>
				})}
			</ul>
		</>
	}
}