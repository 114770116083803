import React from "react"

import "styles/views/services/sections/how-it-works"

import Delimiter from "components/Delimiter"
import { AltrisServiceStep } from "typings/General"

export interface ServicesPageHowItWorksSectionProps {
	steps: AltrisServiceStep[]
}

export interface ServicesPageHowItWorksSectionState {

}

export default
class ServicesPageHowItWorksSection
extends React.Component<ServicesPageHowItWorksSectionProps, ServicesPageHowItWorksSectionState> {
	render() {
		const { steps } = this.props
		return <>
			<section className="sp-how-it-works">
				<div className="inner-wrapper u-bound">
					<Delimiter light>
						How it works
					</Delimiter>
					<div className="hiw-content">
						{steps.map((item, i) => {
							return <div
								key={i}
								className="hiw-item"
							>
								<div className="count">
									<span className="big">
										{(i + 1).toString().padStart(2, "0")}
									</span>
									<span>
										/ {steps.length.toString().padStart(2, "0")}
									</span>
								</div>
								<header>
									<h3>
										{item.title}
									</h3>
								</header>
								<p>
									{item.description}
								</p>
							</div>
						})}
					</div>
				</div>
			</section>
		</>
	}
}