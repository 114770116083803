import AccordionContent from "components/AccordionContent"
import FancyButton from "components/Buttons/Fancy"
import SolutionsList from "components/SolutionsList"
import React from "react"
import Main from "stores/Main"

import "styles/views/homepage/sections/solutions"

export interface HomepageSolutionsSectionProps {

}

export interface HomepageSolutionsSectionState {
	selectedService: string
}

export default
class HomepageSolutionsSection
extends React.Component<HomepageSolutionsSectionProps, HomepageSolutionsSectionState> {
	state
		: HomepageSolutionsSectionState
		= {
			selectedService: Main.defaultService.key,
		}

	timeoutId
		: number

	timeoutThreshold
		: number
		= 3000

	autoscroller
		: null | HTMLDivElement

	componentDidMount(): void {
		document.addEventListener("scroll", this.handleScroll)
	}

	componentWillUnmount(): void {
		this.clearTimeout()
		document.removeEventListener("scroll", this.handleScroll)
	}
		
	initDeferredSwitch = () => {
		this.clearTimeout()
		this.timeoutId = window.setTimeout(this.nextService, this.timeoutThreshold)
	}

	clearTimeout = () => {
		clearTimeout(this.timeoutId)
	}

	nextService = () => {
		let index = Main.services.findIndex(item => item.key == this.state.selectedService) + 1
		if (index >= Main.services.length)
			index = 0

		this.selectService(Main.services[index].key)
	}

	selectService = (
		key: string,
	) => {
		this.clearTimeout()
		this.setState({
			selectedService: key,
		})
		this.initDeferredSwitch()
	}

	handleScroll = () => {
		if (!this.autoscroller)
			return

		const { top, bottom } = this.autoscroller.getBoundingClientRect()
		const offsetTop = top
		const offsetBottom = document.body.offsetHeight - bottom

		const isVisible = offsetTop > -100 && offsetBottom > -100
		if (isVisible)
			this.initDeferredSwitch()
		else
			this.clearTimeout()
	}

	render() {
		const selectedService = Main.services.find(item => item.key == this.state.selectedService)

		return <>
			<section className="hp-solutions">
				<div className="inner-wrapper u-bound">
					<header>
						<h2>
							We deliver a diverse array of state-of-the-art solutions.
						</h2>
						<p>
							We help our clients automate operations, optimize workflows, transform businesses, and acquire the finest products in the field.
						</p>
					</header>
					<div
						ref={r => this.autoscroller = r}
						className="content"
					>
						<div className="categories">
							{Main.services.map(service => {
								return <React.Fragment key={service.key}>
									<FancyButton
										key={service.key}
										mode="light"
										active={this.state.selectedService == service.key}
										onClick={() => this.selectService(service.key)}
									>
										{service.label}
									</FancyButton>
									<div className="mobile-list">
										<AccordionContent
											expanded={service.key == this.state.selectedService}
										>
											<div className="solutions-list-wrapper">
												<SolutionsList items={service.solutions} />
											</div>
										</AccordionContent>
									</div>
								</React.Fragment>
							})}
						</div>
						<div className="desktop-list">
							<SolutionsList items={selectedService?.solutions ?? []} />
						</div>
					</div>
				</div>
			</section>
		</>
	}
}