import React from "react"

import "styles/components/sections/contacts"

import Main from "stores/Main"

export interface CrosspageContactsSectionProps {

}

export interface CrosspageContactsSectionState {

}

export default
class CrosspageContactsSection
extends React.Component<CrosspageContactsSectionProps, CrosspageContactsSectionState> {
	render() {
		const { email, socials } = Main.contacts
		return <>
			<section className="c-contacts">
				<div className="inner-wrapper u-bound">
					<h2>
						Join us on a journey of innovation
						<br />
						<span>Contact us!</span>
					</h2>
					<div className="links">
						<a
							href={`mailto:${email}`}
							target="_blank"
							rel="noopener"
							className="email"
						>
							{email}
						</a>
						<div className="socials">
							<p>
								Our social media
							</p>
							<div className="list">
								{Object.keys(socials).map(key => {
									return <a
										key={key}
										href={socials[key]}
										target="_blank"
										rel="noopener"
									>
										{key}
									</a>
								})}
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	}
}