import React from "react"
import { Link } from "react-router-dom"

import "styles/components/sections/projects"

import Routing from "stores/Routing"
import Main from "stores/Main"

import GenericButton from "components/Buttons/Generic"
import FancyButton from "components/Buttons/Fancy"
import Swipeable, { SwipeableDirection } from "components/HOC/Swipeable"
import { list } from "consts/projects"

export interface CrosspageProjectsSectionProps {
	exclude?: string
}

export interface CrosspageProjectsSectionState {
	skip: number
}

export default
class CrosspageProjectsSection
extends React.Component<CrosspageProjectsSectionProps, CrosspageProjectsSectionState> {
	state
		: CrosspageProjectsSectionState
		= {
			skip: 0
		}

	get skipAmount(): number {
		return 1
	}

	get pageAmount(): number {
		if (document.body.offsetWidth > 1100)
			return 3
		if (document.body.offsetWidth > 800)
			return 2
		return 1
	}

	get count(): number {
		return this.props.exclude
			? list.length - 1
			: list.length
	}

	componentDidMount(): void {
		window.addEventListener("resize", this._rerender)
	}

	componentWillUnmount(): void {
		window.removeEventListener("resize", this._rerender)
	}

	_rerender = () => {
		this.forceUpdate()
	}

	skipNext = () => {
		let skip = this.state.skip + this.skipAmount
		if (skip > this.count - this.pageAmount)
			skip = this.count - this.pageAmount

		this.setState({
			skip
		})
	}

	skipPrev = () => {
		let skip = this.state.skip - this.skipAmount
		if (skip < 0)
			skip = 0

		this.setState({
			skip,
		})
	}

	handleSwipe = (
		direction: SwipeableDirection,
	) => {
		switch (direction) {
			case "left":
				return this.skipNext()
			case "right":
				return this.skipPrev()
		}
	}

	render() {
		return <>
			<section className="c-projects">
				<div className="inner-wrapper u-bound">

					<header>
						<h2>
							{this.props.exclude
								? "Check out our other cases"
								: "Stay inspired with our works"
							}
						</h2>
						<GenericButton
							onClick={Main.showContactsForm}
						>
							Collaborate now
						</GenericButton>
					</header>
					<Swipeable
						onSwipe={this.handleSwipe}
					>
						<div className="projects-list">
							<div
								className="pl-inner-wrapper"
								style={{
									"--skip": this.state.skip,
									"--page": this.pageAmount,
								} as React.CSSProperties}
							>
								{list.map(project => {
									if (project.id == this.props.exclude)
										return null

									return <Link
										key={project.id}
										to={Routing.routes.projects.item(project.id)}
										className="pl-card"
									>
										<div className="badges">
											<FancyButton
												mode="dark"
											>
												{Main.services.find(item => item.key == project.category)?.label}
											</FancyButton>
											<span className="arrow">
												<img src={Routing.static("images/icons/arrow_tr_white.svg")} />
											</span>
										</div>
										<div className="content">
											<div className="image-wrapper">
												<img
													src={project.image}
												/>
											</div>
											<h3>
												{project.name}
											</h3>
											<p>
												{project.taskInfo}
											</p>
										</div>
									</Link>
								})}
							</div>
						</div>
					</Swipeable>
					<div className="carouselle-controls">
						<img
							src={Routing.static("images/icons/arrow_long_left.svg")}
							onClick={this.skipPrev}
						/>
						<img
							src={Routing.static("images/icons/arrow_long_right.svg")}
							onClick={this.skipNext}
						/>
					</div>
				</div>
			</section>
		</>
	}
}