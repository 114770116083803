import React from "react"
import { createPortal } from "react-dom"
import { observer } from "mobx-react"

import "styles/components/modals/generic"

export interface GenericModalProps {
	onClose: () => void
	children: (
		close: () => void
	) => React.ReactNode
}

export interface GenericModalState {
	fadeOut: boolean
}

@observer
export default
class GenericModal
extends React.Component<GenericModalProps, GenericModalState> {
	state
		: GenericModalState
		= {
			fadeOut: false,
		}

	fadeOut = () => {
		this.setState({
			fadeOut: true,
		})
	}

	handleAnimationEnd = (
		event: React.AnimationEvent<HTMLDivElement>
	) => {
		if (event.target != event.currentTarget)
			return

		if (event.animationName == "fade-out")
			this.props.onClose()
	}

	render() {
		if (typeof document == "undefined")
			return null

		return createPortal(
			<div
				className={`c-generic-modal fade-${this.state.fadeOut ? "out" : "in"}`}
				onAnimationEnd={this.handleAnimationEnd}
			>
				<div className="gm-inner-wrapper">
					{this.props.children(this.fadeOut)}
					<span className="gm-close">
						<i
							className="fas fa-times"
							onClick={this.fadeOut}
						/>
					</span>
				</div>
			</div>,
			document.body
		)
	}
}